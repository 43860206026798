import React from "react";
import './HomePage.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";

// images
import Review from '../../Images/Review.png'
import next from '../../Images/next.png'
import telephone from '../../Images/telephone.png'
import MainPic from '../../Images/MainPic.jpg'
import blog6 from '../../Images/blog6.jpg'
import chimneysweep from '../../Images/chimneysweep.jpeg'
import Chimney1 from '../../Images/Chimney1.jpeg'
import Chimney2 from '../../Images/Chimney2.jpeg'
import Chimney3 from '../../Images/Chimney3.jpeg'

import clock from '../../Images/clock.png'
import computer from '../../Images/computer.png'
import books from '../../Images/books.png'
import bgnobg from '../../Images/bgnobg.png'

import appmoney from '../../Images/appmoney.png'
import appcalendar from '../../Images/appcalendar.png'
import appdropshipping from '../../Images/appdropshipping.png'
import appsearch from '../../Images/appsearch.png'
import appwallet from '../../Images/appwallet.png'

export default function HomePage({ navigateTo, FaqList, Blogs }) {

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());

    return (
        <div className="HomePage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Riverside, CA Chimney Sweep & Cleaning 951-292-7463 | Best Chimney Sweep</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Riverside, CA Chimney Sweep & Cleaning 951-292-7463 | Best Chimney Sweep" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Riverside, CA Chimney Sweep & Cleaning 951-292-7463 | Best Chimney Sweep" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.riversidechimneysweep.org/" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Riverside Chimney Sweep offers professional chimney cleaning, inspection, repair, and maintenance services in Riverside, CA. Ensure your fireplace operates safely and efficiently with our expert services. Contact us today!" data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Riverside Chimney Sweep offers professional chimney cleaning, inspection, repair, and maintenance services in Riverside, CA. Ensure your fireplace operates safely and efficiently with our expert services. Contact us today!" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Riverside, CA Chimney Sweep & Cleaning 951-292-7463 | Best Chimney Sweep" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap" data-react-helmet="true" />
            </Helmet>
            {/* MainBanner */}

            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>Riverside, CA CHIMNEY SWEEP</h1>
                    <h2>Chimney Cleaning Near Me In Riverside, California</h2>
                </div>
                <div className="MainBannerRight">
                    <img src={chimneysweep} alt="" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="MainBanner2Top2">
                <div className="MainBanner2TopBox">
                    <h2>Riverside CHIMNEY SERVICES</h2>
                    <div className="MainBanner2TopBoxMenu">
                       <a href="/service/Chimney-Cleaning" className="MainBanner2TopBoxBox">
                            <h3>Chimney Cleaning</h3>
                        </a>
                        <a href="/service/Chimney-Inspection" className="MainBanner2TopBoxBox">
                            <h3>Chimney Inspection</h3>
                        </a>
                        <a href="/service/Chimney-Maintenance" className="MainBanner2TopBoxBox">
                            <h3>Chimney Maintenance</h3>
                        </a>
                        <a href="/service/Chimney-Repair" className="MainBanner2TopBoxBox">
                            <h3>Chimney Repair</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div className="MainBannerReviews">
                <div className="MainBannerReviewsHeader">
                    <h1>Proudly recognized as "#1 Chimney Company" by industry experts.</h1>
                </div>
                <div className="MainBannerReviewsMenu">
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Yahoo%21_Finance_logo_2021.png/320px-Yahoo%21_Finance_logo_2021.png" alt="" />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://cdn.worldvectorlogo.com/logos/marketwatch-logo.svg" alt="" />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://1000logos.net/wp-content/uploads/2024/02/Business-Insider-Logo.png" alt="" />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://1000logos.net/wp-content/uploads/2016/10/Associated-Press-logo.png" alt="" />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://1000logos.net/wp-content/uploads/2022/05/Benzinga-Logo.png" alt="" />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://www.iadea.com/wp-content/uploads/2018/10/digital-journal-logo.png" alt="" />
                    </div>
                </div>
            </div>
            <div className="MainBanner2">
                <div className="MainBanner2TopTop">
                    <div className="MainBanner2TopTopLeft">
                        <img src={Chimney1} alt="" />
                    </div>
                    <div className="MainBanner2TopTopRight">
                        <div className="MainBanner2TopBox">
                            <h2>Expert Chimney Sweeping Services</h2>
                            <h3>Keep your home safe and efficient with our professional chimney sweeping.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>Reliable Chimney Inspections & Repairs</h2>
                            <h3>Trust our skilled technicians to inspect and repair your chimney.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>Top-Quality Chimney Maintenance</h2>
                            <h3>We help you prevent fires and enhance the longevity of your chimney system.</h3>
                        </div>
                    </div>
                </div>

                {/* Title */}
                <a href="tel:951-292-7463">CALL NOW 951-292-7463</a>
                <h1>Chimney Sweep Near Me</h1>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <h2>Chimney Cleaning Riverside, CA</h2>
                        <h3>
                            Exceptional Chimney Cleaning Services in Riverside, CA
                            <br />
                            <br />
                            Welcome to Riverside Sweep, your trusted provider of professional chimney cleaning services in Riverside, CA. Maintaining a clean chimney is essential for the safety and efficiency of your fireplace or stove. Over time, soot, creosote, and other debris accumulate inside your chimney, posing fire hazards and reducing efficiency. Our expert technicians are committed to ensuring your chimney remains clean and safe for use.
                            <br />
                            <br />
                            Why Regular Chimney Cleaning is Vital in Riverside, CA
                            <br />
                            <br />
                            Fire Hazard Reduction: The accumulation of creosote, a highly flammable substance, can lead to chimney fires. Regular cleaning removes this buildup, significantly reducing the risk of fire.
                            <br />
                            Improved Efficiency: A clean chimney ensures better airflow and draft, allowing your fireplace or stove to operate more efficiently and produce more heat.
                            <br />
                            <br />
                            Enhanced Air Quality: Removing blockages and buildup prevents smoke and harmful gases from entering your home, ensuring healthier indoor air quality.
                            <br />
                            <br />
                            Prolonged Chimney Life: Routine cleaning and maintenance extend the lifespan of your chimney, preventing costly repairs and ensuring it remains in good condition for years to come.
                            <br />
                        </h3>
                        <a href="/service/Chimney-Cleaning">Learn More</a>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <h2>Chimney Inspection Riverside, CA</h2>
                        <h3>
                            Exceptional Chimney Cleaning Services in Riverside, CA
                            <br />
                            <br />
                            Welcome to Riverside Sweep, your trusted provider of professional chimney cleaning services in Riverside, CA. Maintaining a clean chimney is essential for the safety and efficiency of your fireplace or stove. Over time, soot, creosote, and other debris accumulate inside your chimney, posing fire hazards and reducing efficiency. Our expert technicians are committed to ensuring your chimney remains clean and safe for use.
                            <br />
                            <br />
                            Why Regular Chimney Cleaning is Vital in Riverside, CA
                            <br />
                            <br />
                            Fire Hazard Reduction: The accumulation of creosote, a highly flammable substance, can lead to chimney fires. Regular cleaning removes this buildup, significantly reducing the risk of fire.
                            <br />
                            <br />
                            Improved Efficiency: A clean chimney ensures better airflow and draft, allowing your fireplace or stove to operate more efficiently and produce more heat.
                            <br />
                            <br />
                            Enhanced Air Quality: Removing blockages and buildup prevents smoke and harmful gases from entering your home, ensuring healthier indoor air quality.
                            <br />
                            <br />
                            Prolonged Chimney Life: Routine cleaning and maintenance extend the lifespan of your chimney, preventing costly repairs and ensuring it remains in good condition for years to come.
                        </h3>
                        <a href="/service/Chimney-Inspection">Learn More</a>
                    </div>
                </div>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <h2>Chimney Repair Riverside, CA</h2>
                        <h3>
                            Professional Chimney Repair Services in Riverside, CA
                            <br />
                            <br />
                            At Riverside Sweep, we offer expert chimney repair services to homeowners in Riverside, CA. A well-maintained chimney is essential for the safety and efficiency of your fireplace or stove. Over time, your chimney can suffer from wear and tear, weather damage, and other issues that can compromise its integrity. Our skilled technicians are here to provide comprehensive chimney repairs, ensuring your chimney remains safe and functional.
                            <br />
                            <br />
                            Why Chimney Repair is Essential in Riverside, CA
                            <br />
                            <br />
                            Safety: Damaged chimneys can pose significant safety risks, including the potential for chimney fires and carbon monoxide leaks. Timely repairs address these hazards and ensure your home remains safe.
                            <br />
                            <br />
                            Structural Integrity: Cracks, gaps, and other structural issues can weaken your chimney over time. Repairing these problems helps maintain the structural integrity of your chimney, preventing further damage.
                            <br />
                            <br />
                            Efficiency: A well-maintained chimney operates more efficiently, providing better draft and airflow for your fireplace or stove. This results in improved performance and heat output.
                            <br />
                            <br />
                            Preventing Water Damage: Chimney repairs can prevent water infiltration, which can cause significant damage to the interior of your chimney and home. Repairing leaks and other issues helps protect your chimney from moisture-related problems.
                        </h3>
                        <a href="/service/Chimney-Repair">Learn More</a>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <h2>Chimney Maintenance Riverside, CA</h2>
                        <h3>
                            Top-Notch Chimney Maintenance Services in Riverside, CA
                            <br />
                            <br />
                            At Riverside Sweep, we specialize in providing comprehensive chimney maintenance services to homeowners in Riverside, CA. Regular chimney maintenance is essential for the safety, efficiency, and longevity of your fireplace or stove. Our skilled technicians are dedicated to keeping your chimney in optimal condition, ensuring that it operates safely and efficiently year-round.
                            <br />
                            <br />
                            The Importance of Regular Chimney Maintenance in Riverside, CA
                            <br />
                            <br />
                            Safety: Regular maintenance helps identify and address potential safety hazards such as creosote buildup, cracks, and structural damage. This significantly reduces the risk of chimney fires and carbon monoxide poisoning.
                            <br />
                            <br />
                            Efficiency: A well-maintained chimney operates more efficiently, providing better draft and airflow for your fireplace or stove. This results in improved performance and heat output.
                            <br />
                            <br />
                            Longevity: Routine maintenance extends the lifespan of your chimney by addressing minor issues before they become major problems. Proper care ensures your chimney remains in good condition for years to come.
                            <br />
                            <br />
                            Cost Savings: Preventive maintenance can save you money by avoiding costly repairs and ensuring your heating system runs efficiently, reducing energy costs.

                        </h3>
                        <a href="/service/Chimney-Maintenance">Learn More</a>
                    </div>
                </div>
            </div>
            <div className="MainBanner4">
                <div className="MainBanner4Left">
                    <img src={Chimney3} alt="Riverside CHIMNEY CLEANING" />
                    <div className="MainBanner4LeftBubble1"></div>
                    <div className="MainBanner4LeftBubble2"></div>
                </div>
                <div className="MainBanner4Right">
                    <h1>OUR OUR SERVICES</h1>
                    <h2>At Riverside, California Chimney Sweep, we offer comprehensive chimney services including cleaning, inspection, repair, and maintenance. Our certified technicians ensure your chimney is safe, efficient, and in top condition. We use high-quality materials and state-of-the-art equipment to deliver exceptional results, providing peace of mind and enhancing your home's safety and comfort.</h2>
                    <div className="MainBanner4RightBenefits">
                        <a href="/service/Chimney-Cap-Repair" className="Header1BoxMenuBox">
                            Chimney Cap Repair Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Cleaning" className="Header1BoxMenuBox">
                            Chimney Cleaning Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Construction" className="Header1BoxMenuBox">
                            Chimney Construction Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Crown-Repair" className="Header1BoxMenuBox">
                            Chimney Crown Repair Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Flue-Installation" className="Header1BoxMenuBox">
                            Chimney Flue Installation Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Flue-Repair" className="Header1BoxMenuBox">
                            Chimney Flue Repair Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Inspection" className="Header1BoxMenuBox">
                            Chimney Inspection Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Installation" className="Header1BoxMenuBox">
                            Chimney Installation Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Insulation" className="Header1BoxMenuBox">
                            Chimney Insulation Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Cracks-Repair" className="Header1BoxMenuBox">
                            Chimney Cracks Repair Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Firebox-Paint" className="Header1BoxMenuBox">
                            Chimney Firebox Paint Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Firebox-Cleaining" className="Header1BoxMenuBox">
                            Chimney Firebox Cleaining Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Firebox-Inspection" className="Header1BoxMenuBox">
                            Chimney Firebox Inspection Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Best-Company" className="Header1BoxMenuBox">
                            Chimney Best Company Riverside, Ca
                        </a>
                        <a href="/service/Firebox-Inspection" className="Header1BoxMenuBox">
                            Firebox Inspection Riverside, Ca
                        </a>
                        <a href="/service/Firebox-Sweeping" className="Header1BoxMenuBox">
                            Firebox Sweeping Riverside, Ca
                        </a>
                        <a href="/service/Fireplace-Inspection" className="Header1BoxMenuBox">
                            Fireplace Inspection Riverside, Ca
                        </a>
                        <a href="/service/Firepalce-Repair" className="Header1BoxMenuBox">
                            Firepalce Repair Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Liner-Repair" className="Header1BoxMenuBox">
                            Chimney Liner Repair Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Maintenance" className="Header1BoxMenuBox">
                            Chimney Maintenance Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Pointing" className="Header1BoxMenuBox">
                            Chimney Pointing Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Rain-Cap-Installation" className="Header1BoxMenuBox">
                            Chimney Rain Cap Installation Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Repair" className="Header1BoxMenuBox">
                            Chimney Repair Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Restoration" className="Header1BoxMenuBox">
                            Chimney Restoration Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Flashing-Repair" className="Header1BoxMenuBox">
                            Chimney Flashing Repair Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Liner-Installation" className="Header1BoxMenuBox">
                            Chimney Liner Installation Riverside, Ca
                        </a>
                        <a href="/service/Chimney-Damper-Repair" className="Header1BoxMenuBox">
                            Chimney Damper Repair Riverside, Ca
                        </a>
                    </div>
                </div>
            </div>
            <div className="MainBanner5">
                <div className="MainBanner5Left">
                    <h1>How to get in touch with Chimney Sweep Services in Riverside ?</h1>
                    <div className="MainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Call us.</h3>
                            <h4>Contact our expert team by calling or visiting our website to book Riverside Chimney Sweep Services.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Provide some information.</h3>
                            <h4>Provide your address and specific details to our Chimney Sweep professionals, including any unique aspects of your home that might affect the service.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>To know your situation more.</h3>
                            <h4>Share photos of your chimney to help us understand its current condition and accurately estimate the service needed.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>4</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Date and Time.</h3>
                            <h4>Discuss the urgency of your service needs and agree on a tentative arrival date for our team.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>5</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Our team will do the best.</h3>
                            <h4>Our local service provider will determine the cost based on our standard pricing.</h4>
                        </div>
                    </div>
                    <a href="tel:951-292-7463">CALL NOW 951-292-7463</a>
                </div>
                <div className="MainBanner5Right">
                    <img src={Chimney2} alt="" />
                    <div className="MainBanner5RightBubble1"></div>
                    <div className="MainBanner5RightBubble2"></div>
                </div>

            </div>
            <div className="MainBanner6">
                <h1>Trusted Mobile and Safe Service</h1>
                <div className="MainBanner6List">
                    <div className="MainBanner6TopBox">
                        <h2>Our Mission</h2>
                        <h3>Our mission is to connect you with the top providers of Chimney Sweep Services in Riverside. Using the latest technology and specialized skills, we ensure efficient, high-quality chimney care for your home.</h3>
                    </div>
                    <div className="MainBanner6TopBox">
                        <h2>Our Goals</h2>
                        <h3>Our professionals are part of the premier chimney sweep cleaning network, committed to excellence in every aspect of our business. We emphasize teamwork, bringing together top talent and resources to tackle even the most complex chimney sweep tasks efficiently and effectively.</h3>
                    </div>
                    <div className="MainBanner6TopBox">
                        <h2>Our Values</h2>
                        <h3>Our local service providers in Riverside prioritize the safety of you, your family, pets, and neighbors by maintaining environmental responsibility. Our experts also uphold respect and kindness in all interactions.</h3>
                    </div>
                </div>
            </div>
            <div className="MainBanner6">
                <h1>Real Testimonials from Our Valued Clients</h1>
                <h2>Riverside Sweep provided outstanding chimney cleaning and inspection services. The technician was punctual, professional, and thorough. They explained everything clearly and left my home spotless. I highly recommend Riverside Sweep for their excellent customer service and expertise. My chimney is now clean and safe thanks to their top-notch work!</h2>
                <h3>5 stars company 🌟 🌟 🌟 🌟 🌟</h3>
            </div>
            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                    <a href="/blog">View All</a>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>

                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>
            <div className="MainBanner7">
                <div className="MainBanner7Left">
                    <h1>The Importance of Professional Chimney Sweep Services in Riverside</h1>
                    <h2>Keeping your chimney clean and well-maintained is essential for the safety and efficiency of your fireplace or stove. In Riverside, regular chimney sweeping is a critical task that ensures your chimney operates smoothly and reduces the risk of fire hazards. Here’s why professional chimney sweep services are vital and what you can expect from the process.</h2>
                    <h2>
                        {/* (888) 269-4246 */}
                        <br />
                        Monday – Friday
                        <br />
                        8:00 a.m. – 8:00 p.m. ET
                    </h2>
                </div>
                <div className="MainBanner7Right">
                    <img src={MainPic} alt="" />
                    <div className="MainBanner7RightBubble1"></div>
                    <div className="MainBanner7RightBubble2"></div>
                </div>
            </div>
        </div>
    )
}

